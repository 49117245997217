/* Modified from https://github.com/mmistakes/minimal-mistakes/blob/master/assets/css/main.scss */
/* NOTE: Any $variable overrides need to be done before the theme's @import statements. */

/*
  Increase max-width from 1280px to 1400px to make posts slightly wider; ref:
  https://github.com/mmistakes/minimal-mistakes/discussions/1373#discussioncomment-1090246
*/
$max-width: 1400px;

/*
  Disable page intro animations as described in
  https://mmistakes.github.io/minimal-mistakes/docs/stylesheets/#disabling-animations
*/
$intro-transition: none;


@charset "utf-8";
@import "minimal-mistakes/skins/dark"; // skin
@import "minimal-mistakes"; // main partials

/* Modified from https://github.com/mmistakes/minimal-mistakes/blob/master/assets/css/main.scss */


/* # Miscellaneous changes */

/* Don't decorate (underline) any links */
a {
  text-decoration: none;
}

/* Hide Next/Previous buttons on posts */
article .pagination {
  display: none;
}

/* Add border to site logo */
.site-logo img {
 border-radius: 5px;
}


/* # Article list on homepage and archive page */

/* Make article list title/subtitle larger */
.archive .archive__item {
  font-size: 1.35rem;
}
.archive .archive__item-title {
  font-size: 1.6rem;
}


/* # Single article date view & all figcaptions */

@include breakpoint(max-width $large) { /* mobile only */
  .layout--single .page__meta-date, figcaption {
    font-size: 0.9rem;
  }
}


/* # Author sidebar */

/* By default the sidebar (showing author) is partially transparent unless hovered over; always make it fully visible. */
.sidebar {
  opacity: 1;
}
/* Bump the image size a bit */
.author__avatar img {
  max-width: 200px;
}
/* Hide the "Follow" button that appears on mobile */
.author__urls-wrapper .btn {
  display: none;
}
/* Mobile styling changes */
@include breakpoint(max-width $large) { /* mobile only */
  .author__bio {
    font-size: 1.35rem;
  }
  .author__bio p {
    margin-top: 0;
  }
  .sidebar > div {
    margin-bottom: 0;
  }
  .hide-sidebar-on-mobile .sidebar {
    display: none;
  }
}


/* # Projects */

/* Bring project image and heading text closer together */
.feature__wrapper .archive__item-title {
  margin-top: 0.6em;
}
.feature__wrapper a.feature__item {
  text-decoration: none !important;
  color: $text-color;
  transition-duration: 0.06s;
}
.feature__wrapper a.feature__item:hover {
  opacity: 0.65;
}
.feature__wrapper a.feature__item .archive__item {
    margin-top: 0.75em;
  @include breakpoint(max-width $large) { /* mobile only */
    margin-top: 2em;
    .archive__item-excerpt {
      font-size: 1rem;
    }
  }
}

